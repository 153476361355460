import React, { Fragment } from "react";
import PropTypes, { InferProps } from "prop-types";
import "./Lehrplan21TableBasic.css";

export function Lehrplan21TableBasic(props: InferProps<typeof Lehrplan21TableBasic.propTypes>) {

  var prevItem: any = null;

  return <>
    <table className="lp21-table-basic table table-rounded">
      <tbody>
        {props.items && props.items.map((item: any) => {
          var renderedItem = renderItem(props, item, prevItem);
          prevItem = item;
          return renderedItem;
        })}
      </tbody>
    </table>
  </>;
}

function renderItem(props: InferProps<typeof Lehrplan21TableBasic.propTypes>, item: any, prevItem: any): JSX.Element {

  var classNames: any[] = [];
  var className = classNames.join(" ");

  // Limit displayed levels
  var showLevels = ["fach", "kompetenzbereich", "handlungsaspekt", "kompetenz", "kompetenzstufe"];
  if (props.levelUntil && props.levelUntil !== "") {
    var levelUntilIndex = showLevels.indexOf(props.levelUntil);
    showLevels = showLevels.slice(0, levelUntilIndex + 1);
  }
  if (props.skipLevels && props.skipLevels !== null) {
    showLevels = showLevels.filter((l) => (props.skipLevels?.includes(l) ?? false) === false)
  }

  var fachSameAsPrev = (item.fachbereichName === prevItem?.fachbereichName && item.fachName === prevItem?.fachName);
  var kompetenzbereichSameAsPrev = (item.kompetenzbereichName === prevItem?.kompetenzbereichName);
  var handlungsaspektSameAsPrev = (item.handlungsaspektName === prevItem?.handlungsaspektName);

  var kompetenzSameAsPrev = (
    item.kompetenzCode === prevItem?.kompetenzCode
    && item.kompetenzName === prevItem?.kompetenzName
  );

  var kompetenzAndAufbauSameAsPrev = (
    item.kompetenzCode === prevItem?.kompetenzCode
    && item.kompetenzName === prevItem?.kompetenzName
    && item.aufbauName === prevItem?.aufbauName
  );

  var kompetenzstufeSameAsPrev = (
    item.kompetenzstufeCode === prevItem?.kompetenzstufeCode
  );

  return <Fragment key={item.id}>
    {showLevels.includes("fach") && !fachSameAsPrev && renderFach(props, item, className)}
    {showLevels.includes("kompetenzbereich") && !kompetenzbereichSameAsPrev && renderKompetenzbereich(props, item, className)}
    {showLevels.includes("handlungsaspekt") && !handlungsaspektSameAsPrev && renderHandlungsaspekt(props, item, className)}
    {showLevels.includes("kompetenz") && !kompetenzAndAufbauSameAsPrev && renderKompetenz(props, item, className, kompetenzSameAsPrev)}

    {showLevels.includes("kompetenzstufe") && <>
      {renderKompetenzstufe(props, item, className, kompetenzstufeSameAsPrev)}
    </>
    }
  </Fragment>;
}

function renderFach(props: InferProps<typeof Lehrplan21TableBasic.propTypes>, item: any, className: string): JSX.Element {
  return <>
    <tr key={`${item.id}_fach`} className={`lp21-fach ${className}`}>
      <td><a id={item.fachCode ?? item.fachbereichCode} />{item.fachCode ?? item.fachbereichCode}</td>
      <td colSpan={2}>{item.fachName ?? item.fachbereichName}</td>
    </tr>
    {props.onRenderCustomRow && props.onRenderCustomRow(item, item.fachCode ? "fach" : "fachbereich", item.fachCode ? item.fachId : item.fachbereichId)}
  </>;
}

function renderKompetenzbereich(props: InferProps<typeof Lehrplan21TableBasic.propTypes>, item: any, className: string): JSX.Element {
  return <>
    <tr key={`${item.id}_kompetenzbereich`} className={`lp21-kompetenzbereich ${className}`}>
      <td>{item.kompetenzbereichCode}</td>
      <td colSpan={2}>{item.kompetenzbereichName}</td>
    </tr>
    {props.onRenderCustomRow && props.onRenderCustomRow(item, "kompetenzbereich", item.kompetenzbereichId)}
  </>;
}

function renderHandlungsaspekt(props: InferProps<typeof Lehrplan21TableBasic.propTypes>, item: any, className: string): JSX.Element {
  const skipRenderingCustomColumn = props.skipRenderingCustomColumnForLevels && props.skipRenderingCustomColumnForLevels.includes("handlungsaspekt");
  return <Fragment>
    <tr key={`${item.id}_handlungsaspekt`} className={`lp21-handlungsaspekt ${className}`}>
      <td>{item.handlungsaspektCode}</td>
      <td>{item.handlungsaspektName}</td>
      {!skipRenderingCustomColumn && props.onRenderCustomColumns && props.onRenderCustomColumns(item, "handlungsaspekt", item.handlungsaspektId)}
    </tr>
    {props.onRenderCustomRow && props.onRenderCustomRow(item, "handlungsaspekt", item.handlungsaspektId)}
  </Fragment>;
}

function renderKompetenz(props: InferProps<typeof Lehrplan21TableBasic.propTypes>, item: any, className: string, kompetenzSameAsPrev: boolean): JSX.Element {
  const skipRenderingCustomColumn = props.skipRenderingCustomColumnForLevels && props.skipRenderingCustomColumnForLevels.includes("kompetenz");
  return <Fragment>
    <tr key={item.id + "_kompetenz"} className={`lp21-kompetenz ${className}`}>
      <td>
        {!kompetenzSameAsPrev &&
          <p className="lp21-kompetenz">{item.kompetenzCode}</p>
        }
      </td>
      <td colSpan={skipRenderingCustomColumn ? 2 : 1}>
        {!kompetenzSameAsPrev &&
          <p className="lp21-kompetenz">{item.kompetenzName}</p>
        }
        {!props.hideAufbauHeading && item.aufbauName && <p className="lp21-aufbau">{item.aufbauName}</p>}
        {/* <p>Die Schülerinnen und Schüler ...</p> */}
      </td>
      {!skipRenderingCustomColumn && props.onRenderCustomColumns && props.onRenderCustomColumns(item, "kompetenz", item.kompetenzId)}
    </tr>
    {!kompetenzSameAsPrev && props.onRenderCustomRow && props.onRenderCustomRow(item, "kompetenz", item.kompetenzId)}
  </Fragment>;
}

function renderKompetenzstufe(props: InferProps<typeof Lehrplan21TableBasic.propTypes>, item: any, className: string, kompetenzstufeSameAsPrev: boolean) {
  const skipRenderingCustomColumn = props.skipRenderingCustomColumnForLevels && props.skipRenderingCustomColumnForLevels.includes("kompetenzstufe");

  const customColumns = props.onRenderCustomColumns && props.onRenderCustomColumns(item, "kompetenzstufe", item.id);

  // No content?
  if (!customColumns) {
    return <></>;
  }

  return <Fragment>
    <tr key={item.id + "_kompetenzstufe"} className={`lp21-kompetenzstufe ${className}`}>
      <td>{!kompetenzstufeSameAsPrev && item.kompetenzstufeCode}</td>
      {!skipRenderingCustomColumn && customColumns}
    </tr>
  </Fragment>;
}

Lehrplan21TableBasic.propTypes = {
  items: PropTypes.array.isRequired,
  levelUntil: PropTypes.string,
  onRenderCustomColumns: PropTypes.func,
  onRenderCustomRow: PropTypes.func,
  skipLevels: PropTypes.array,
  skipRenderingCustomColumnForLevels: PropTypes.array,
  hideAufbauHeading: PropTypes.bool,
};

export default Lehrplan21TableBasic;
